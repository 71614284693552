import {environment} from 'environments/environment';

export class AlgoliaUtil {

    static APP_ID = 'JU8A9KCQ4B';
    static SEARCH_API_KEY = 'bddb0dc7abd3a2c2fb84c58dca13e788';
    static USERS_API_KEY = '0b0f51ab9f26eea61a1cb141cf1e3d26';
    static CHILDREN_API_KEY = '75cbbc8161a434cfb09981f6526059cd';
    static CHILDREN_API_KEY_DEV = '4afa837933004ffcb5b516df0c159ab8';
    static WOMAN_API_KEY = '0589fb2a67440339de3d8a4a37e42dd2';
    static WOMAN_API_KEY_DEV = '07dcbbcffaf759ae5b89eb526620532b';
    static ORGANIZATION_API_KEY = '113f4642c6b9997d31cecdde5a50c9fd';
    static ORGANIZATION_UNIQUE_API_KEY = '920dba40fd9eab7b10463b0b44b9de45';
    static VISITS_API_KEY = 'b8985bb57c6e478fe47e73104077276a';
    static ORGANIZATION = 'organization';
    static ORGANIZATION_UNIQUE = 'organization_unique';
    static USERS = 'users';
    static CASES = 'cases';
    static CASES_DEV = 'cases_dev';
    static WOMEN = 'woman';
    static WOMAN_DEV = 'woman_dev';
    static VISITS = 'cases_visits';

    static getVisitsIndex() {
        return this.VISITS;
    }

    static getWomenIndex() {
        return this.WOMEN;
    }

    static getWomenIndexDesc() {
        return 'woman_desc';
    }

    static getVisitsWomanIndex() {
        return 'woman_visits';
    }

    static getChildrenIndex() {
        if (environment.production) {
            return this.CASES;
        } else {
            return this.CASES_DEV;
        }
    }

    static getUsersIndex() {
        return this.USERS;
    }

    static getChildrenIndexDesc() {
        return 'cases_desc';
    }

    static getVisitsCasesIndex() {
        return 'cases_visits';
    }

    static getOrganizationIndex() {
        return this.ORGANIZATION;
    }

    static getChildrenAPIKey() {
        if (environment.production) {
            return this.CHILDREN_API_KEY;
        } else {
            return this.CHILDREN_API_KEY_DEV;
        }
    }

    static getUsersAPIKey() {
        return this.USERS_API_KEY;
    }

    static getWomenAPIKey() {
        return this.WOMAN_API_KEY;
    }

    static getOrganizationAPIKey() {
        return this.ORGANIZATION_API_KEY;
    }

    static getOrganizationUniqueIndex() {
        // if (environment.production) {
            return 'organizations_unique';
        // } else {
        //     return 'organizations_unique_dev';
        // }
    }

    static getSearchApiKey() {
        return this.SEARCH_API_KEY;
    }

    static normalizeText(text: string) {
        return text.replace(/\s/g, '').toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

}
